import React from "react";
import "./Modal.css";
import { Button } from "antd";

const Modal = (props) => {

  // render detail info prompt
  return props.data ?(
    <div>
        <div className="Modal">
            <h3>
                Item Details
            </h3>

            <h5>
                Item Name: {props.data.name}
            </h5>
            
            <h5>
                Description: {props.data.description}
            </h5>

            <h5>
                Price: {props.data.price}
            </h5>

            <Button
                className="Button"
                type="primary"
                onClick={props.close}
            >
                Close
            </Button>
        </div>
        <div className="Shadow" />
    </div>
  ) : null;
};

export default Modal;