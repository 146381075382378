const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const addItem = async (newItem) => {
  const response = await fetch(BACKEND_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newItem),
  });

  const data = await response.json();
  if (!response.ok) {
    const errorMsg = data?.message;
    throw new Error(errorMsg);
  }

  return data;
};

const deleteItem = async (itemId) => {
  const response = await fetch(`${BACKEND_URL}/${itemId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const errorMsg = await response.text();
    throw new Error(errorMsg);
  }

  return;
};

const sortItems = async () => {
  const response = await fetch(`${BACKEND_URL}/sort`, {
    method: "GET",
  });
  return response.json();
};

const getItems = async () => {
  const response = await fetch(BACKEND_URL, {
    method: "GET",
  });
  console.log("GET in services.js");
  console.log("BACKEND_URL", BACKEND_URL);
  // console.log(response.json());
  return response.json();
};

const service = {
  addItem,
  getItems,
  deleteItem,
  sortItems,
};

export default service;
