import { Input, Space, Button } from "antd";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { addItemAsync } from "../../redux/items/thunks";
import "./OrderForm.css";

export const OrderForm = () => {
  let emptyItem = {
    name: "",
    description: "",
    quantity: "",
    price: "",
    image: "",
  };

  let inputs = [
    { name: "Item Name", id: "name" },
    { name: "Item Description", id: "description" },
    { name: "Item Quantity", id: "quantity" },
    { name: "Item Price", id: "price" },
    { name: "Item Image", id: "image" },
  ];

  const [item, setItem] = useState(emptyItem);
  const dispatch = useDispatch();

  return (
    // input data for inventory order form
    <div className="NewOrderItemContainer">
      {inputs.map((data) => (
        <React.Fragment key={data.id}>
          <div>{data.name}</div>
          <Input
            value={item[data.id]}
            placeholder={data.name}
            onChange={(e) => setItem({ ...item, [data.id]: e.target.value })}
          />
        </React.Fragment>
      ))}

      <br />

      <Space wrap>
        {/* Button for Add Item */}
        <Button
          type="primary"
          disabled={!item.name || !item.quantity || !item.price || !item.image}
          onClick={() => {
            dispatch(addItemAsync(item));
            setItem(emptyItem);
          }}
        >
          Add Item
        </Button>
        {/* Button for Reset order form to empty */}
        <Button onClick={() => setItem(emptyItem)}>Reset</Button>
      </Space>
    </div>
  );
};
