import { Table, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import "./OrderList.css";
import Modal from "../Modal/Modal.js";
import { getItemsAsync, deleteItemAsync, sortItemAsync } from "../../redux/items/thunks";

export const OrderList = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const items = useSelector(state => state.items.list);

    console.log('Getting items from OrderList');
    console.log(items);
    console.log('End console from OrderList');

    // render tabular data in the current order list
    const columns = [
        // {
        //     title: "Order ID",
        //     dataIndex: "id",
        //     key: "id",
        //     render: (text) => text
        // },
        {
            title: "Item Name",
            dataIndex: "name",
            key: "name",
            render: (text) => text
        },
        {
            title: "Item Description",
            dataIndex: "description",
            key: "description",
            render: (text) => text
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (text) => text
        },
        {
            title: "Unit Price",
            dataIndex: "price",
            key: "price",
            render: (text) => text
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (url) => <img className="ImageContainer" src={url} alt="Pic Not Available"/>
        },
        {
            title: "Remove",
            dataIndex: "id",
            key: "remove",
            render: (id) => 
            <Button onClick={() => dispatch(deleteItemAsync(id))}>Click to Delete</Button>
        }
    ];

    useEffect(() => {
        dispatch(getItemsAsync());
    }, [dispatch]);

    return (
        <>
            <Modal data={isModalOpen} close={() => setIsModalOpen(false)}/>
            <div className="ItemsContainer">
                <h1>Current Order List</h1>
                <Button onClick={() => dispatch(sortItemAsync())}>Click to Sort by Price (DESC)</Button>
                <Table
                    rowClassName="TableRow"
                    columns={columns}
                    dataSource={items}
                    pagination={false}
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: (event) => {
                                // Ignore clicks on the delete button
                                if (event.target.tagName === 'BUTTON') return;
                                setIsModalOpen(row)
                            }
                        };
                    }}
                />
            </div>
        </>
    );
}