import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionTypes } from './actionTypes';
import ItemService from './service';

export const getItemsAsync = createAsyncThunk(
  actionTypes.GET_ITEMS,
  async () => {
    return await ItemService.getItems();
  }
);

export const addItemAsync = createAsyncThunk(
  actionTypes.ADD_ITEM,
  async (item) => {
    return await ItemService.addItem(item);
  }
);

export const deleteItemAsync = createAsyncThunk(
  actionTypes.DELETE_ITEM,
  async (id) => {
    return await ItemService.deleteItem(id);
  }
);

export const sortItemAsync = createAsyncThunk(
  actionTypes.SORT_ITEM,
  async () => {
    return await ItemService.sortItems();
  }
);
