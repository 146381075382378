import React from 'react';
import {OrderForm} from "./components/OrderForm/OrderForm";
import {OrderList} from "./components/OrderList/OrderList";
import "./App.css";

const App = () => {
    return (
        <div className="App">
            <h1>Inventory Order Form</h1>
            <OrderForm />
            <OrderList />
        </div>
    );
}

export default App;
